
import { WEEK_DAY_LABEL_MAP } from '@/constants';
import { WeekDayKeys, YextEntityData } from 'ignite360-core';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'yext-value-display',
})
export default class YextValueDisplay extends Vue {
  @Prop({ required: true })
  readonly value!: unknown;

  @Prop({ required: true, type: String })
  readonly relatedKey!: keyof YextEntityData;

  get weekDayKeys(): WeekDayKeys[] {
    return Object.keys(WEEK_DAY_LABEL_MAP) as WeekDayKeys[];
  }

  weekDayLabel(weekday: WeekDayKeys): string {
    return WEEK_DAY_LABEL_MAP[weekday];
  }
}
