
import YextValueDisplay from '@/components/yext/YextValueDisplay.vue';
import { RemoveNull } from '@/types';
import equal from 'fast-deep-equal/es6';
import {
  Bot,
  BotData,
  getBotDataFromYextEntity,
  YEXT_IMPORT_OPTIONS,
  YextEntityData,
  YextImportOption,
} from 'ignite360-core';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

function isEqualValue(a: any, b: any): boolean {
  const clone = (val: any) => {
    return JSON.parse(JSON.stringify(val));
  };
  const cloneA = clone(a);
  const cloneB = clone(b);
  const removeHolidayHours = (val: any) => {
    if (val?.holidayHours) {
      delete val.holidayHours;
    }
  };
  removeHolidayHours(cloneA);
  removeHolidayHours(cloneB);

  return equal(cloneA, cloneB);
}

@Component({
  name: 'yext-import-business-data-modal',
  components: { YextValueDisplay },
})
export default class YextImportBusinessDataModal extends Vue {
  @Prop({ required: true, type: Boolean, default: false })
  readonly show!: boolean;

  @Prop({ required: true, type: Object })
  readonly botData!: RemoveNull<BotData>;

  @Prop({ required: true, default: '' })
  readonly yextEntityId!: Bot['entityId'];

  selectedPropertySet = new Set<keyof YextEntityData>();

  get relatedYextEntity(): YextEntityData | undefined {
    return this.$store.state.yext.entities.find(
      (entity: YextEntityData) => entity.meta.id === this.yextEntityId,
    );
  }

  get importOptions(): YextImportOption[] {
    const relatedEntity = this.relatedYextEntity;
    if (!relatedEntity) {
      return [];
    }
    return YEXT_IMPORT_OPTIONS.filter((option) => {
      return relatedEntity[option.key];
    });
  }

  get importOptionsWithDifferences(): YextImportOption[] {
    return this.importOptions.filter((option) => {
      const currentValue = this.botData[option.dataKey || (option.key as keyof BotData)];
      const newValue = option.transform
        ? option.transform(this.relatedYextEntity![option.key])
        : this.relatedYextEntity![option.key];
      return !isEqualValue(currentValue, newValue);
    });
  }

  get tableData() {
    return this.importOptionsWithDifferences.map((option) => ({
      key: option.key,
      label: option.label,
      current: this.botData[option.dataKey || (option.key as keyof BotData)],
      new: option.transform
        ? option.transform(this.relatedYextEntity![option.key])
        : this.relatedYextEntity![option.key],
    }));
  }

  get allChecked(): boolean {
    return this.selectedPropertySet.size === this.importOptionsWithDifferences.length;
  }

  toggleSelectedProperty(property: keyof YextEntityData) {
    const newSet = new Set(this.selectedPropertySet);
    if (newSet.has(property)) {
      newSet.delete(property);
    } else {
      newSet.add(property);
    }
    this.selectedPropertySet = newSet;
  }

  toggleAllChecked() {
    this.selectedPropertySet = this.allChecked
      ? new Set()
      : new Set(this.tableData.map((data) => data.key));
  }

  onSubmit() {
    if (!this.relatedYextEntity) return;

    this.$emit(
      'submit',
      getBotDataFromYextEntity(this.relatedYextEntity, this.selectedPropertySet),
    );
    this.selectedPropertySet.clear();
  }

  onScroll(event: WheelEvent) {
    (this.$refs.scrollContainer as HTMLElement).scrollBy({ left: event.deltaY });
  }

  @Watch('yextEntityId', { immediate: true })
  async onYextEntityIdChange() {
    if (this.yextEntityId) {
      await this.$store.dispatch('yext/fetchEntity', this.yextEntityId);
    }
  }
}
