
import Timepicker from '@/components/ui/Timepicker.vue';
import { OpeningHoursSpecification } from 'ignite360-core';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'business-hours-input',
  components: { Timepicker },
})
export default class BusinessHoursInput extends Vue {
  @Prop({ required: false, type: Object })
  readonly value!: OpeningHoursSpecification;

  @Prop({ required: false, type: String, default: '' })
  readonly id!: string;

  addInterval() {
    const openIntervals = this.value.openIntervals?.slice() || [];
    openIntervals.push({
      start: '08:00',
      end: '18:00',
    });
    this.emitInput({
      ...this.value,
      openIntervals,
    });
  }

  removeInterval(index: number) {
    const openIntervals = this.value.openIntervals?.slice() || [];
    if (openIntervals[index]) {
      openIntervals.splice(index, 1);
      this.emitInput({
        ...this.value,
        openIntervals,
      });
    }
  }

  toggleIsClosed() {
    this.emitInput({
      ...this.value,
      isClosed: !this.value.isClosed,
    });
  }

  onTimepickerInput(propertyKey: 'start' | 'end', index: number, event: string) {
    const openIntervals = this.value.openIntervals?.slice() || [];
    if (!openIntervals[index]) return;
    openIntervals[index][propertyKey] = event;
    this.emitInput({
      ...this.value,
      openIntervals,
    });
  }

  emitInput(newValue: OpeningHoursSpecification) {
    this.$emit('input', newValue);
  }
}
