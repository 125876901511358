
import BotBusinessDataCard from '@/components/bot/BotBusinessDataCard.vue';
import BotDataSourcesListCard from '@/components/bot/BotDataSourcesListCard.vue';
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'project-content-general',
  components: { BotBusinessDataCard, BotDataSourcesListCard },
})
export default class ProjectContentGeneral extends mixins(ProjectViewMixin) {}
