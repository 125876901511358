
import BusinessHoursInput from '@/components/ui/BusinessHoursInput.vue';
import YextImportBusinessDataModal from '@/components/yext/YextImportBusinessDataModal.vue';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import { RemoveNull } from '@/types';
import isEqual from 'fast-deep-equal/es6';
import { BotData } from 'ignite360-core';
import _merge from 'lodash.merge';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';

type BotBusinessData = RemoveNull<Omit<BotData, 'paymentOptions' | 'isAddressHidden'>>;

@Component({
  name: 'bot-business-data-card',
  components: { YextImportBusinessDataModal, BusinessHoursInput },
})
export default class BotBusinessDataCard extends mixins(BotMixin) {
  showModal = false;

  isSubmitting = false;

  botData: BotBusinessData = this.resetValue();

  get dataHasChanged(): boolean {
    return !isEqual(this.botData, this.resetValue());
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      await this.$updateBot({
        id: this.bot.id,
        data: {
          data: this.botData,
        },
      });
      this.$notify.success('Successfully updated business-data');
    } catch (e) {
      this.$notify.error({
        title: 'Updating business-data failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  onImportFromYext(data: Partial<BotData>) {
    this.showModal = false;
    this.botData = _merge(this.botData, data);
  }

  @Watch('bot', { immediate: true, deep: true })
  private reset() {
    this.botData = this.resetValue();
  }

  private resetValue(): BotBusinessData {
    return {
      name: this.bot.data.name || '',
      email: this.bot.data.email || '',
      websiteContactPage: this.bot.data.websiteContactPage || '',
      phoneNumber: this.bot.data.phoneNumber || '',
      address: {
        line1: this.bot.data.address?.line1 || '',
        postalCode: this.bot.data.address?.postalCode || '',
        city: this.bot.data.address?.city || '',
        line2: this.bot.data.address?.line2 || '',
        region: this.bot.data.address?.region || '',
        countryCode: this.bot.data.address?.countryCode || '',
      },
      hours: {
        holidayHours: this.bot.data.hours?.holidayHours || {
          isClosed: true,
          openIntervals: [],
        },
        monday: this.bot.data.hours?.monday || {
          isClosed: true,
          openIntervals: [],
        },
        tuesday: this.bot.data.hours?.tuesday || {
          isClosed: true,
          openIntervals: [],
        },
        wednesday: this.bot.data.hours?.wednesday || {
          isClosed: true,
          openIntervals: [],
        },
        thursday: this.bot.data.hours?.thursday || {
          isClosed: true,
          openIntervals: [],
        },
        friday: this.bot.data.hours?.friday || {
          isClosed: true,
          openIntervals: [],
        },
        saturday: this.bot.data.hours?.saturday || {
          isClosed: true,
          openIntervals: [],
        },
        sunday: this.bot.data.hours?.sunday || {
          isClosed: true,
          openIntervals: [],
        },
      },
    };
  }
}
